



















































































































import api from "@/api/index"; //ABP API接口
import {Component, Vue} from "vue-property-decorator";
import PagedTableView from "@/components/PagedTableView/index.vue";
import {DataDictionaryDtoPagedResultDto, DataDictionaryDto} from "@/api/appService";
import AbSelect from "@/components/AbSelect/index.vue";
import {getTrainStatusList} from "@/utils/enumToSelectItem";
import AgileAuditTag from "@/components/AgileAuditTag/index.vue";

@Component({
  name: "TrainList",
  components: {
    PagedTableView,
    AbSelect,
    AgileAuditTag
  }
})
export default class TrainList extends Vue {
  statusList = getTrainStatusList();
  typeList: DataDictionaryDto[] = [];
  queryForm: any = {
    title: undefined,
    trainTypeId: undefined,
    status: undefined,
    startDate: undefined,
    endDate: undefined
  }

  created() {
    this.fetchDataDictionary()
  }

  // 获取表数据
  async fetchData(params: any) {
    return await api.train.getAll(params);
  }

  async fetchDataDictionary() {
    await api.dataDictionary
      .getDataDictionaryListByKey({key: "TrainType", maxResultCount: 1000})
      .then((res: DataDictionaryDtoPagedResultDto) => {
        this.typeList = res.items!;
      });
  }

  handleCreate() {
    this.$router.push({name: 'trainCreate'})
  }


  // 跳转详情页
  handleDetail(item: any) {
    this.$router.push({name: 'trainDetail', params: {'id': item.id!.toString()}})
  }

  // 考题管理
  handleTrainExamList(item: any) {
    this.$router.push({name: 'trainExamList', params: {'id': item.id!.toString()}})
  }

  // 跳转编辑页
  handleEdit(item: any) {
    this.$router.push({name: 'trainEdit', params: {'id': item.id!.toString()}})
  }

  handleDelete(item: any) {
    this.$confirm('确定删除吗?', '提示').then(() => {
      api.train.delete({id: item.id}).then(res => {
        this.$message.success('删除成功');
        (this as any).$bus.$emit('refresh-data');
      });
    })
  }

  handlePublish(item: any) {
    this.$confirm('确定发布吗?', '提示').then(() => {
      api.train.publish({id: item.id}).then(res => {
        this.$message.success('发布成功');
        (this as any).$bus.$emit('refresh-data');
      });
    })
  }

  // 取消发布
  handleCancelPublish(item: any) {
    let confirmMessage = '确定取消发布吗?';
    if ((item.signUpCount ?? 0) > 0) {
      confirmMessage = '确定取消发布吗?当前培训已有用户报名，取消培训会造成报名用户无法继续培训，请谨慎操作';
    }
    this.$confirm(confirmMessage, '提示').then(() => {
      api.train.cancelPublish({id: item.id}).then(res => {
        this.$message.success('取消发布成功');
        (this as any).$bus.$emit('refresh-data');
      });
    })
  }
}

import { ApplyStatus, ActivityStatus, TrainStatus, StarRatingStatus } from "@/api/appService";

export function getActivityStatusList() {
  return [
    {
      label: "全部",
      value: undefined,
    },
    {
      label: "草稿",
      value: ActivityStatus.Saved,
    },
    {
      label: "已发布",
      value: ActivityStatus.Published,
    },
    {
      label: "审核中",
      value: ActivityStatus.Submited,
    },
    {
      label: "已拒绝",
      value: ActivityStatus.SendBacked,
    },
  ];
}

export function getvolunteerApplyStatusList() {
  return [
    {
      label: "全部",
      value: undefined,
    },
    {
      label: "待审核",
      value: ApplyStatus.Check,
    },
    {
      label: "已通过",
      value: ApplyStatus.Approved,
    },
    {
      label: "已退回",
      value: ApplyStatus.Return,
    },
  ];
}

export function getTrainStatusList() {
  return [
    {
      label: "全部",
      value: undefined,
    },
    {
      label: "草稿",
      value: TrainStatus.Saved,
    },
    {
      label: "已发布",
      value: TrainStatus.Published,
    },
  ];
}

// export function getVolunteerPlatformSyncStatusList() {
//   return [
//     {
//       label: "全部",
//       value: undefined
//     },
//     {
//       label: "未执行",
//       value: VolunteerCloudPlatformSyncStatus.Wait
//     },
//     {
//       label: "执行成功",
//       value: VolunteerCloudPlatformSyncStatus.Success
//     },
//     {
//       label: "执行失败",
//       value: VolunteerCloudPlatformSyncStatus.Fail
//     },
//   ]
// }

export function getStarRatingStatusList() {
  return [
    {
      label: "全部",
      value: undefined,
    },
    {
      label: "草稿",
      value: StarRatingStatus.Saved,
    },
    {
      label: "已发布",
      value: StarRatingStatus.Published,
    },
  ];
}
